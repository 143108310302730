import Web3 from "web3";
import IronVestJson from "../utils/IronVest.json";
import ApproveRouterJson from "../utils/ApproveRouterAbi.json";
import { AbiItem } from "web3-utils";
import { poolCreatedFailed } from "../_apis/vesting";
import { smartContractAddress } from "../utils/const.utils";

export class Web3Helper {
  web3Client: Web3;
  static addSimpleVesting: any;
  constructor(web3: Web3) {
    this.web3Client = web3;
  }

  async checkTransaction(hash: string, callback: any, close: any, failed: any) {
    console.log(hash, "hash");
    try {
      const response = await this.web3Client.eth.getTransactionReceipt(
        hash,
        function (err, rec) {
          return rec;
        }
      );

      if (response) {
        console.log(response, "response");
        if (response.status) {
          setTimeout(() => "checking progress", 500);
          callback(response);
          return true;
        }
        console.log("transaction failed");
        close();
        return false;
      } else {
        setTimeout(
          () => this.checkTransaction(hash, callback, close, failed),
          50000
        );
      }
    } catch (e: any) {
      console.log(e);
      //@ts-ignore
      failed(
        `Transaction error occured : ${
          e?.message || "invalid Transaction Id provided"
        }`
      );
    }
  }

  async addCliffVesting(
    poolName: any,
    vestingTime: any,
    tokenAddress: any,
    cliffPeriod: any,
    cliffPercentage: number,
    cliffVestingTime: any,
    userAddresses: any,
    userAllocation: any,
    signature: any,
    key: any,
    walletAddress: any,
    setIsProcessing: any,
    setIsInProgress: any,
    setTransactionId: any,
    poolId: any,
    userAuthToken: any,
    setTransitionWalletDialog: any,
    currentWallet: any,
    setIsSafeTransaction: any
  ) {
    try {
      // our contract taking percentages in 10000 that why we are multiplying with 100
      var percentage = cliffPercentage * 100;
      const stateVariable = new this.web3Client.eth.Contract(
        IronVestJson.abi as AbiItem[],
        smartContractAddress
      );

      if (currentWallet === 2) {
        setTimeout(() => {}, 1000);
        setIsSafeTransaction(true);
        setIsInProgress(true);
      }

      const txHash = await (window as any).ethereum.request({
        method: "eth_sendTransaction",
        params: [
          {
            from: walletAddress,
            to: smartContractAddress,
            data: stateVariable.methods
              .addCliffVesting(
                poolName,
                vestingTime,
                cliffVestingTime,
                cliffPeriod,
                tokenAddress,
                percentage,
                userAddresses,
                userAllocation,
                signature,
                key
              )
              .encodeABI(),
          },
        ],
      });

      if (currentWallet != 2) {
        setTransactionId(txHash);
        setIsProcessing(false);
        setIsInProgress(true);
      }
      setTransactionId(txHash || "");
      console.log("Transaction response", txHash);

      let response;
      while (response == undefined) {
        response = await window.ethereum.request({
          method: "eth_getTransactionReceipt",
          params: [txHash],
        });
        await new Promise((resolve) => setTimeout(resolve, 1000));
      }
      console.log("response", response);

      const decodedLog = await this.getLogsFromTransactionReceipt(
        response?.logs,
        "cliff"
      );
      console.log("decodedLog", decodedLog);
      console.log("decodedLog poolid", decodedLog?.poolId);
      return decodedLog;
    } catch (e) {
      setIsProcessing(false);
      setIsInProgress(false);
      setTransitionWalletDialog(false);
      poolCreatedFailed(poolId, userAuthToken)
        .then((response: any) => {
          console.log("Pool Creation Failed");
        })
        .catch((e) => {
          console.log(e);
        });
      console.log(e);
    }
  }
  async addSimpleVesting(
    poolName: any,
    vestingTime: any,
    tokenAddress: any,
    userAddresses: any,
    userAllocation: any,
    signature: any,
    key: any,
    walletAddress: any,
    setIsProcessing: any,
    setIsInProgress: any,
    setTransactionId: any,
    poolId: any,
    userAuthToken: any,
    setTransitionWalletDialog: any,
    currentWallet: any,
    setIsSafeTransaction: any
  ) {
    try {
      const stateVariable = new this.web3Client.eth.Contract(
        IronVestJson.abi as AbiItem[],
        smartContractAddress
      );
      console.log("hello simple vesting", smartContractAddress);
      if (currentWallet === 2) {
        setTimeout(() => {}, 1000);
        setIsSafeTransaction(true);
        setIsInProgress(true);
      }
      const payload = {
        from: walletAddress,
        to: smartContractAddress,
        data: stateVariable.methods
          .addVesting(
            poolName,
            vestingTime,
            tokenAddress,
            userAddresses,
            userAllocation,
            signature,
            key
          )
          .encodeABI(),
      };
      console.log("payload", payload);
      const txHash = await (window as any).ethereum.request({
        method: "eth_sendTransaction",
        params: [payload],
      });

      if (currentWallet != 2) {
        setTransactionId(txHash);
        setIsProcessing(false);
        setIsInProgress(true);
      }
      let response;
      while (response == undefined) {
        response = await window.ethereum.request({
          method: "eth_getTransactionReceipt",
          params: [txHash],
        });
        await new Promise((resolve) => setTimeout(resolve, 1000));
      }
      const decodedLog = await this.getLogsFromTransactionReceipt(
        response?.logs,
        "simple"
      );
      console.log("decodedLog", decodedLog);
      console.log("decodedLog poolid", decodedLog?.poolId);
      return decodedLog;
    } catch (e) {
      setIsProcessing(false);
      setIsInProgress(false);
      setTransitionWalletDialog(false);
      poolCreatedFailed(poolId, userAuthToken)
        .then((response: any) => {
          console.log("Pool Creation Failed simple vesting");
        })
        .catch((e) => {
          console.log(e);
        });
      console.log(e);
    }
  }

  async allowanceMethod(vestingContractAddress: any, userWalletAddress: any) {
    try {
      const stateVariable = new this.web3Client.eth.Contract(
        ApproveRouterJson.abi as AbiItem[],
        vestingContractAddress
      );
      const response = await stateVariable.methods
        .allowance(userWalletAddress, smartContractAddress)
        .call();
      console.log("allowanceMethod", response);
      return response;
    } catch (e) {
      console.log(e);
    }
  }
  async symbolMethod(vestingContractAddress: any) {
    try {
      const stateVariable = new this.web3Client.eth.Contract(
        ApproveRouterJson.abi as AbiItem[],
        vestingContractAddress
      );
      const response = await stateVariable.methods.symbol().call();
      return response;
    } catch (e) {
      console.log(e);
    }
  }
  async approveMethod(
    vestingContractAddress: any,
    walletAddress: any,
    totalVesting: any,
    setTransitionWalletDialog: any,
    setIsProcessing: any,
    currentWallet: any,
    setIsInProgress: any,
    setIsSafeTransaction: any
  ) {
    try {
      let fixedAllocation =
        "115792089237316195423570985008687907853269984665640564039457584007913129639935";
      setTransitionWalletDialog(true);
      setIsProcessing(true);
      const stateVariable = new this.web3Client.eth.Contract(
        ApproveRouterJson.abi as AbiItem[],
        vestingContractAddress
      );
      const txHash = await (window as any).ethereum.request({
        method: "eth_sendTransaction",
        params: [
          {
            from: walletAddress,
            to: vestingContractAddress,
            data: stateVariable.methods
              .approve(smartContractAddress, fixedAllocation)
              .encodeABI(),
          },
        ],
      });
      if (txHash && currentWallet === 2) {
        setTimeout(() => {}, 1000);
        setIsSafeTransaction(true);
        setIsInProgress(true);
      }
      let response;
      while (response == undefined) {
        response = await window.ethereum.request({
          method: "eth_getTransactionReceipt",
          params: [txHash],
        });
        await new Promise((resolve) => setTimeout(resolve, 1000));
      }
      console.log("Approve in Web3", response);
      return txHash;
    } catch (e) {
      setTransitionWalletDialog(false);
      setIsProcessing(false);
      console.log(e);
    }
  }

  async cliffClaimable(poolId: any, userWalletAddress: any) {
    //same extra -> claimable
    try {
      const stateVariable = new this.web3Client.eth.Contract(
        IronVestJson.abi as AbiItem[],
        smartContractAddress
      );
      console.log(
        "cliff Claim",
        poolId,
        userWalletAddress,
        smartContractAddress
      );
      const response = await stateVariable.methods
        .cliffClaimable(poolId, userWalletAddress)
        .call();
      console.log("cliff Claim response ", response);

      return response;
    } catch (e) {
      console.log(e);
    }
  }
  async simpleClaimable(poolId: any, userWalletAddress: any) {
    try {
      const stateVariable = new this.web3Client.eth.Contract(
        IronVestJson.abi as AbiItem[],
        smartContractAddress
      );
      console.log(
        "simple Claim",
        poolId,
        userWalletAddress,
        smartContractAddress
      );

      const response = await stateVariable.methods
        .claimable(poolId, userWalletAddress)
        .call();
      console.log("simple Claim response ", response);

      return response;
    } catch (e) {
      console.log(e);
    }
  }
  async nonCliffClaimable(poolId: any, userWalletAddress: any) {
    //same
    try {
      const stateVariable = new this.web3Client.eth.Contract(
        IronVestJson.abi as AbiItem[],
        smartContractAddress
      );
      const response = await stateVariable.methods
        .nonCliffClaimable(poolId, userWalletAddress)
        .call();
      return response;
    } catch (e) {
      console.log(e);
    }
  }
  async userCliffInfo(poolId: any, userWalletAddress: any) {
    try {
      const stateVariable = new this.web3Client.eth.Contract(
        IronVestJson.abi as AbiItem[],
        smartContractAddress
      );
      const response = await stateVariable.methods
        .userCliffInfo(poolId, userWalletAddress)
        .call();
      return response;
    } catch (e) {
      console.log(e);
    }
  }
  async userSimpleInfo(poolId: any, userWalletAddress: any) {
    // userInfo
    try {
      const stateVariable = new this.web3Client.eth.Contract(
        IronVestJson.abi as AbiItem[],
        smartContractAddress
      );
      const response = await stateVariable.methods
        .userInfo(poolId, userWalletAddress)
        .call();
      return response;
    } catch (e) {
      console.log(e);
    }
  }
  async userNonCliffInfo(poolId: any, userWalletAddress: any) {
    try {
      const stateVariable = new this.web3Client.eth.Contract(
        IronVestJson.abi as AbiItem[],
        smartContractAddress
      );
      const response = await stateVariable.methods
        .userNonCliffInfo(poolId, userWalletAddress)
        .call();
      return response;
    } catch (e) {
      console.log(e);
    }
  }
  async claimCliffTokens(
    poolId: any,
    walletAddress: any,
    setTransactionId: any,
    setTransitionWalletDialog: any,
    setIsProcessing: any,
    setIsInProgress: any,
    currentWallet: any,
    isSafe: any,
    setIsSafeTransaction: any
  ) {
    try {
      setTransitionWalletDialog(true);
      setIsProcessing(true);
      const stateVariable = new this.web3Client.eth.Contract(
        IronVestJson.abi as AbiItem[],
        smartContractAddress
      );

      if (isSafe) {
        setTimeout(() => {}, 1000);
        setIsSafeTransaction(true);
        setIsProcessing(true);
      }

      const txHash = await (window as any).ethereum.request({
        method: "eth_sendTransaction",
        params: [
          {
            from: walletAddress,
            to: smartContractAddress,
            data: stateVariable.methods.claimCliff(poolId).encodeABI(),
          },
        ],
      });

      if (!isSafe) {
        setTransactionId(txHash);
        setIsProcessing(false);
        setIsInProgress(true);
      }
      let response;
      while (response == undefined) {
        response = await window.ethereum.request({
          method: "eth_getTransactionReceipt",
          params: [txHash],
        });
        await new Promise((resolve) => setTimeout(resolve, 1000));
      }
      console.log("Claim cliff tokens", response);
      console.log("Claim tokens ", txHash);
      return txHash;
    } catch (e) {
      setTransitionWalletDialog(false);
      setIsProcessing(false);
      console.log(e);
    }
  }
  async claimNonCliffTokens(
    poolId: any,
    walletAddress: any,
    setTransactionId: any,
    setTransitionWalletDialog: any,
    setIsProcessing: any,
    setIsInProgress: any,
    currentWallet: any,
    isSafe: any,
    setIsSafeTransaction: any
  ) {
    try {
      setTransitionWalletDialog(true);
      setIsProcessing(true);
      const stateVariable = new this.web3Client.eth.Contract(
        IronVestJson.abi as AbiItem[],
        smartContractAddress
      );

      if (isSafe) {
        setTimeout(() => {}, 1000);
        setIsSafeTransaction(true);
        setIsProcessing(true);
      }

      const txHash = await (window as any).ethereum.request({
        method: "eth_sendTransaction",
        params: [
          {
            from: walletAddress,
            to: smartContractAddress,
            data: stateVariable.methods.claimNonCliff(poolId).encodeABI(),
          },
        ],
      });

      if (!isSafe) {
        setTransactionId(txHash);
        setIsProcessing(false);
        setIsInProgress(true);
      }
      let response;
      while (response == undefined) {
        response = await window.ethereum.request({
          method: "eth_getTransactionReceipt",
          params: [txHash],
        });
        await new Promise((resolve) => setTimeout(resolve, 1000));
      }
      console.log("Claim Simple tokens", response);
      console.log("Claim non cliff tokens", txHash);
      return txHash;
    } catch (e) {
      setTransitionWalletDialog(false);
      setIsProcessing(false);
      console.log(e);
    }
  }

  async claimVestingTokens(
    poolId: any,
    walletAddress: any,
    setTransactionId: any,
    setTransitionWalletDialog: any,
    setIsProcessing: any,
    setIsInProgress: any,
    isSafe: any,
    setIsSafeTransaction: any
  ) {
    try {
      setTransitionWalletDialog(true);
      const stateVariable = new this.web3Client.eth.Contract(
        IronVestJson.abi as AbiItem[],
        smartContractAddress
      );

      if (isSafe) {
        setTimeout(() => {}, 1000);
        setIsSafeTransaction(true);
        setIsProcessing(true);
      }
      const txHash = await (window as any).ethereum.request({
        method: "eth_sendTransaction",
        params: [
          {
            from: walletAddress,
            to: smartContractAddress,
            data: stateVariable.methods.claim(poolId).encodeABI(),
          },
        ],
      });

      if (!isSafe) {
        setTransactionId(txHash);
        setIsProcessing(false);
        setIsInProgress(true);
      }
      console.log("Claim vesting tokens", txHash);
      return txHash;
    } catch (e) {
      setTransitionWalletDialog(false);
      setIsProcessing(false);
      console.log(e);
    }
  }

  async getLogsFromTransactionReceipt(logs: any, type: string) {
    let logDataAndTopic = undefined;

    if (logs?.length) {
      for (const log of logs) {
        if (log?.topics?.length) {
          let topicIndex;
          if (type === "cliff") {
            console.log("cliff");
            topicIndex = await this.findCliffVestingEvent(log.topics);
            console.log("topicIndex", topicIndex);
          } else if (type === "simple") {
            topicIndex = await this.findSimpleVestingEvent(log.topics);
          }
          if (topicIndex !== undefined && (topicIndex as any) >= 0) {
            logDataAndTopic = {
              data: log.data,
              topics: log.topics,
            };
            console.log("logDataAndTopic", logDataAndTopic);
            break;
          }
        }
      }
      let swapEventInputs;
      if (type === "cliff") {
        swapEventInputs = IronVestJson.abi.find(
          (abi) => abi.name === "CliffAddVesting" && abi.type === "event"
        )?.inputs;
      } else if (type === "simple") {
        swapEventInputs = IronVestJson.abi.find(
          (abi) => abi.name === "AddVesting" && abi.type === "event"
        )?.inputs;
      }

      if (logDataAndTopic?.data && logDataAndTopic.topics) {
        const decodedLog = this.web3Client.eth.abi.decodeLog(
          swapEventInputs as any,
          logDataAndTopic.data,
          logDataAndTopic.topics.slice(1)
        );

        return decodedLog;
      }
    }
  }

  async findSimpleVestingEvent(topics: any[]) {
    let vestingHash = Web3.utils.sha3(
      "AddVesting(address,uint256,string,uint256,uint256,address,uint256,address[],uint256[])"
    );
    if (topics?.length) {
      return topics.findIndex((topic) => topic === vestingHash);
    } else {
      return undefined;
    }
  }

  async findCliffVestingEvent(topics: any[]) {
    let vestingHash = Web3.utils.sha3(
      "CliffAddVesting(address,uint256,string,uint256,uint256,uint256,address,uint256,address[],uint256[])"
    );
    console.log("vestingHash", vestingHash);
    if (topics?.length) {
      console.log("topics", topics.length);
      return topics.findIndex((topic) => topic === vestingHash);
    } else {
      return undefined;
    }
  }
}
export default Web3Helper;